import {
  MAX_URL_LENGTH,
  urlFormatErrorMsg
} from 'validation/rules/shared/consts';
import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { FormData } from 'types/saiyouPageEditing/FormData';

type Rules = RegisterOptions<FormData, 'sns.instagramUrl'>;

/**
 * InstagramのURLのバリデーションルールを返す
 * @returns {Rules} バリデーションルール
 */
export const useInstagramUrlValidationRules = (): Rules => {
  const propertyName = 'InstagramのURL';

  return useMemo(
    () => ({
      validate: {
        validUrl: (value) => {
          if (!value) return undefined;

          return value.startsWith('https://www.instagram.com/') &&
            value.length <= MAX_URL_LENGTH
            ? undefined
            : urlFormatErrorMsg(propertyName);
        }
      }
    }),
    []
  );
};
