import {
  MAX_URL_LENGTH,
  urlFormatErrorMsg
} from 'validation/rules/shared/consts';
import { useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';
import { FormData } from 'types/saiyouPageEditing/FormData';

type Rules = RegisterOptions<FormData, 'sns.facebookUrl'>;

/**
 * FacebookのURLのバリデーションルールを返す
 * @returns {Rules} バリデーションルール
 */
export const useFacebookUrlValidationRules = (): Rules => {
  const propertyName = 'FacebookのURL';

  return useMemo(
    () => ({
      validate: {
        validUrl: (value) => {
          if (!value) return undefined;
          return value.startsWith('https://www.facebook.com/') &&
            value.length <= MAX_URL_LENGTH
            ? undefined
            : urlFormatErrorMsg(propertyName);
        }
      }
    }),
    []
  );
};
