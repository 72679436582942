/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：管理画面API
 *      [共通認可ロジック]       ・リクエストヘッダー のIdToken に含まれるアカウントNoに紐づくユーザー情報とパスパラメータのCompに紐づく企業情報をバイトル管理API ログインユーザー情報取得APIから取得     　　・ユーザーがアクセス可能なCompかどうかの判断はバイトル管理APIで行う     　　・ユーザーがアクセス不可能であれば403エラーを返す     ・取得したログインユーザーのロールコードとパスパラメータCompMgrNoに応じて制御     　バイトル管理APIでの認可を通った　かつロールコード1〜5・10であればアクセス可能とする       ※ 上記条件を満たさなければ403エラー       ※ さらに細かい権限制御が必要な場合は各エンドポイント単位で制御を行う
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PageSettingAboutJobDto } from './PageSettingAboutJobDto';
import {
  PageSettingAboutJobDtoFromJSON,
  PageSettingAboutJobDtoFromJSONTyped,
  PageSettingAboutJobDtoToJSON
} from './PageSettingAboutJobDto';
import type { PageSettingFaqDto } from './PageSettingFaqDto';
import {
  PageSettingFaqDtoFromJSON,
  PageSettingFaqDtoFromJSONTyped,
  PageSettingFaqDtoToJSON
} from './PageSettingFaqDto';
import type { PageSettingJobPointDto } from './PageSettingJobPointDto';
import {
  PageSettingJobPointDtoFromJSON,
  PageSettingJobPointDtoFromJSONTyped,
  PageSettingJobPointDtoToJSON
} from './PageSettingJobPointDto';
import type { PageSettingProcessDto } from './PageSettingProcessDto';
import {
  PageSettingProcessDtoFromJSON,
  PageSettingProcessDtoFromJSONTyped,
  PageSettingProcessDtoToJSON
} from './PageSettingProcessDto';
import type { PageSettingStaffInterviewDto } from './PageSettingStaffInterviewDto';
import {
  PageSettingStaffInterviewDtoFromJSON,
  PageSettingStaffInterviewDtoFromJSONTyped,
  PageSettingStaffInterviewDtoToJSON
} from './PageSettingStaffInterviewDto';

/**
 * 外部公開用プレビュー設定
 * @export
 * @interface PageSettingAdminReferenceResDtoPreview
 */
export interface PageSettingAdminReferenceResDtoPreview {
  /**
   * デザイン
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  designType?: PageSettingAdminReferenceResDtoPreviewDesignTypeEnum;
  /**
   * カラー
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  color?: PageSettingAdminReferenceResDtoPreviewColorEnum;
  /**
   * 全体レイアウト
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  generalLayoutType?: PageSettingAdminReferenceResDtoPreviewGeneralLayoutTypeEnum;
  /**
   * レイアウト画像がアップロードされているパス。レイアウトがミニマル以外の場合は必須。
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  generalLayoutImagePath?: string;
  /**
   * キャッチフレーズ。改行文字は文字数制限に含めない
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  catchPhrase?: string;
  /**
   * 会社紹介欄を表示するか
   * @type {number}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  aboutCorporationVisibility?: PageSettingAdminReferenceResDtoPreviewAboutCorporationVisibilityEnum;
  /**
   * 会社紹介テキスト
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  aboutCorporationText?: string;
  /**
   * お仕事紹介欄レイアウト
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  aboutJobPartialLayoutType?: PageSettingAdminReferenceResDtoPreviewAboutJobPartialLayoutTypeEnum;
  /**
   * お仕事紹介欄の各職種の情報
   * @type {Array<PageSettingAboutJobDto>}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  aboutJobList?: Array<PageSettingAboutJobDto>;
  /**
   * スタッフインタビューを表示するか
   * @type {number}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  staffInterviewVisibility?: PageSettingAdminReferenceResDtoPreviewStaffInterviewVisibilityEnum;
  /**
   * スタッフインタビュー欄レイアウト
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  staffInterviewPartialLayoutType?: PageSettingAdminReferenceResDtoPreviewStaffInterviewPartialLayoutTypeEnum;
  /**
   * スタッフインタビュー欄各項目
   * @type {Array<PageSettingStaffInterviewDto>}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  staffInterviewList?: Array<PageSettingStaffInterviewDto>;
  /**
   * 募集中のお仕事一覧固定表示
   * @type {Array<string>}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  jobMgrNos?: Array<string>;
  /**
   * お仕事のポイント表示
   * @type {number}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  jobPointVisibility?: PageSettingAdminReferenceResDtoPreviewJobPointVisibilityEnum;
  /**
   * お仕事のポイント欄各項目
   * @type {Array<PageSettingJobPointDto>}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  jobPointList?: Array<PageSettingJobPointDto>;
  /**
   * 応募のプロセス表示
   * @type {number}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  processVisibility?: PageSettingAdminReferenceResDtoPreviewProcessVisibilityEnum;
  /**
   * 応募のプロセス各項目
   * @type {Array<PageSettingProcessDto>}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  processList?: Array<PageSettingProcessDto>;
  /**
   * 採用担当からのメッセージ表示
   * @type {number}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  messageVisibility?: PageSettingAdminReferenceResDtoPreviewMessageVisibilityEnum;
  /**
   * 採用担当からのプロフィール情報
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  messageProfile?: string;
  /**
   * 採用担当からのメッセージ本文
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  messageBody?: string;
  /**
   * 画像がアップロードされているパス
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  messageImagePath?: string;
  /**
   * よくある質問表示
   * @type {number}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  faqVisibility?: PageSettingAdminReferenceResDtoPreviewFaqVisibilityEnum;
  /**
   * よくある質問各項目
   * @type {Array<PageSettingFaqDto>}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  faqList?: Array<PageSettingFaqDto>;
  /**
   * 表示用企業名
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  dispCorpName?: string;
  /**
   * ロゴがアップロードされているパス
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  logoImgPath?: string;
  /**
   * ホームページURL
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  homepageUrl?: string;
  /**
   * ツイッターURL
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  twitterUrl?: string;
  /**
   * FacebookURL
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  facebookUrl?: string;
  /**
   * LINE URL
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  lineUrl?: string;
  /**
   * InstagramURL
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  instagramUrl?: string;
  /**
   * YouTube URL
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  youtubeUrl?: string;
  /**
   * 企業所在地
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  corpAddress?: string;
  /**
   * 設立年
   * @type {number}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  corpEstablishedYear?: number;
  /**
   * 資本金
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  corpCapital?: string;
  /**
   * 売上高
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  corpEarnings?: string;
  /**
   * 株式公開区分
   * @type {number}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  corpStockCd?: PageSettingAdminReferenceResDtoPreviewCorpStockCdEnum;
  /**
   * 代表者名
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  corpPresidentName?: string;
  /**
   * 従業員数
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  corpEmployees?: string;
  /**
   * 派遣許可番号
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  corpLicenseNo?: string;
  /**
   * 有料職業紹介事業許可番号
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  corpLicenseNo2?: string;
  /**
   * 福利厚生・待遇
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  corpWelfare?: string;
  /**
   * 事業内容 改行文字は文字数制限に含めない(改行最大160個まで)
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  corpBusiness?: string;
  /**
   * 採用TOPページ表示設定
   * @type {number}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  topPageVisibility?: PageSettingAdminReferenceResDtoPreviewTopPageVisibilityEnum;
  /**
   * サイトタイトル
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  siteTitle?: string;
  /**
   * サイトブランド
   * @type {Array<string>}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  siteBrandList?: Array<string>;
  /**
   * ターゲット（雇用形態）
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  siteTargetKoyoKeitai?: PageSettingAdminReferenceResDtoPreviewSiteTargetKoyoKeitaiEnum;
  /**
   * サイト説明文
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  siteDescription?: string;
  /**
   * 最終更新時刻
   * @type {string}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  updatedAt?: string;
  /**
   * 公開状態
   * @type {number}
   * @memberof PageSettingAdminReferenceResDtoPreview
   */
  isOpen?: number;
}

/**
 * @export
 */
export const PageSettingAdminReferenceResDtoPreviewDesignTypeEnum = {
  Basic: 'basic',
  Stylish: 'stylish',
  Pop: 'pop',
  Natural: 'natural'
} as const;
export type PageSettingAdminReferenceResDtoPreviewDesignTypeEnum =
  (typeof PageSettingAdminReferenceResDtoPreviewDesignTypeEnum)[keyof typeof PageSettingAdminReferenceResDtoPreviewDesignTypeEnum];

/**
 * @export
 */
export const PageSettingAdminReferenceResDtoPreviewColorEnum = {
  Red: 'red',
  Orange: 'orange',
  Green: 'green',
  Blue: 'blue',
  Gray: 'gray',
  Purple: 'purple'
} as const;
export type PageSettingAdminReferenceResDtoPreviewColorEnum =
  (typeof PageSettingAdminReferenceResDtoPreviewColorEnum)[keyof typeof PageSettingAdminReferenceResDtoPreviewColorEnum];

/**
 * @export
 */
export const PageSettingAdminReferenceResDtoPreviewGeneralLayoutTypeEnum = {
  Minimal: 'minimal',
  Standard: 'standard',
  Rich: 'rich'
} as const;
export type PageSettingAdminReferenceResDtoPreviewGeneralLayoutTypeEnum =
  (typeof PageSettingAdminReferenceResDtoPreviewGeneralLayoutTypeEnum)[keyof typeof PageSettingAdminReferenceResDtoPreviewGeneralLayoutTypeEnum];

/**
 * @export
 */
export const PageSettingAdminReferenceResDtoPreviewAboutCorporationVisibilityEnum =
  {
    NUMBER_0: 0,
    NUMBER_1: 1
  } as const;
export type PageSettingAdminReferenceResDtoPreviewAboutCorporationVisibilityEnum =
  (typeof PageSettingAdminReferenceResDtoPreviewAboutCorporationVisibilityEnum)[keyof typeof PageSettingAdminReferenceResDtoPreviewAboutCorporationVisibilityEnum];

/**
 * @export
 */
export const PageSettingAdminReferenceResDtoPreviewAboutJobPartialLayoutTypeEnum =
  {
    Default: 'default',
    Card: 'card'
  } as const;
export type PageSettingAdminReferenceResDtoPreviewAboutJobPartialLayoutTypeEnum =
  (typeof PageSettingAdminReferenceResDtoPreviewAboutJobPartialLayoutTypeEnum)[keyof typeof PageSettingAdminReferenceResDtoPreviewAboutJobPartialLayoutTypeEnum];

/**
 * @export
 */
export const PageSettingAdminReferenceResDtoPreviewStaffInterviewVisibilityEnum =
  {
    NUMBER_0: 0,
    NUMBER_1: 1
  } as const;
export type PageSettingAdminReferenceResDtoPreviewStaffInterviewVisibilityEnum =
  (typeof PageSettingAdminReferenceResDtoPreviewStaffInterviewVisibilityEnum)[keyof typeof PageSettingAdminReferenceResDtoPreviewStaffInterviewVisibilityEnum];

/**
 * @export
 */
export const PageSettingAdminReferenceResDtoPreviewStaffInterviewPartialLayoutTypeEnum =
  {
    Default: 'default',
    Card: 'card'
  } as const;
export type PageSettingAdminReferenceResDtoPreviewStaffInterviewPartialLayoutTypeEnum =
  (typeof PageSettingAdminReferenceResDtoPreviewStaffInterviewPartialLayoutTypeEnum)[keyof typeof PageSettingAdminReferenceResDtoPreviewStaffInterviewPartialLayoutTypeEnum];

/**
 * @export
 */
export const PageSettingAdminReferenceResDtoPreviewJobPointVisibilityEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1
} as const;
export type PageSettingAdminReferenceResDtoPreviewJobPointVisibilityEnum =
  (typeof PageSettingAdminReferenceResDtoPreviewJobPointVisibilityEnum)[keyof typeof PageSettingAdminReferenceResDtoPreviewJobPointVisibilityEnum];

/**
 * @export
 */
export const PageSettingAdminReferenceResDtoPreviewProcessVisibilityEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1
} as const;
export type PageSettingAdminReferenceResDtoPreviewProcessVisibilityEnum =
  (typeof PageSettingAdminReferenceResDtoPreviewProcessVisibilityEnum)[keyof typeof PageSettingAdminReferenceResDtoPreviewProcessVisibilityEnum];

/**
 * @export
 */
export const PageSettingAdminReferenceResDtoPreviewMessageVisibilityEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1
} as const;
export type PageSettingAdminReferenceResDtoPreviewMessageVisibilityEnum =
  (typeof PageSettingAdminReferenceResDtoPreviewMessageVisibilityEnum)[keyof typeof PageSettingAdminReferenceResDtoPreviewMessageVisibilityEnum];

/**
 * @export
 */
export const PageSettingAdminReferenceResDtoPreviewFaqVisibilityEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1
} as const;
export type PageSettingAdminReferenceResDtoPreviewFaqVisibilityEnum =
  (typeof PageSettingAdminReferenceResDtoPreviewFaqVisibilityEnum)[keyof typeof PageSettingAdminReferenceResDtoPreviewFaqVisibilityEnum];

/**
 * @export
 */
export const PageSettingAdminReferenceResDtoPreviewCorpStockCdEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1
} as const;
export type PageSettingAdminReferenceResDtoPreviewCorpStockCdEnum =
  (typeof PageSettingAdminReferenceResDtoPreviewCorpStockCdEnum)[keyof typeof PageSettingAdminReferenceResDtoPreviewCorpStockCdEnum];

/**
 * @export
 */
export const PageSettingAdminReferenceResDtoPreviewTopPageVisibilityEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1
} as const;
export type PageSettingAdminReferenceResDtoPreviewTopPageVisibilityEnum =
  (typeof PageSettingAdminReferenceResDtoPreviewTopPageVisibilityEnum)[keyof typeof PageSettingAdminReferenceResDtoPreviewTopPageVisibilityEnum];

/**
 * @export
 */
export const PageSettingAdminReferenceResDtoPreviewSiteTargetKoyoKeitaiEnum = {
  Normal: 'normal',
  Regular: 'regular',
  Contract: 'contract',
  Haken: 'haken',
  Outsg: 'outsg',
  NlimEhaken: 'nlim_ehaken',
  SyokaiRhaken: 'syokai_rhaken'
} as const;
export type PageSettingAdminReferenceResDtoPreviewSiteTargetKoyoKeitaiEnum =
  (typeof PageSettingAdminReferenceResDtoPreviewSiteTargetKoyoKeitaiEnum)[keyof typeof PageSettingAdminReferenceResDtoPreviewSiteTargetKoyoKeitaiEnum];

/**
 * Check if a given object implements the PageSettingAdminReferenceResDtoPreview interface.
 */
export function instanceOfPageSettingAdminReferenceResDtoPreview(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function PageSettingAdminReferenceResDtoPreviewFromJSON(
  json: any
): PageSettingAdminReferenceResDtoPreview {
  return PageSettingAdminReferenceResDtoPreviewFromJSONTyped(json, false);
}

export function PageSettingAdminReferenceResDtoPreviewFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PageSettingAdminReferenceResDtoPreview {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    designType: !exists(json, 'designType') ? undefined : json['designType'],
    color: !exists(json, 'color') ? undefined : json['color'],
    generalLayoutType: !exists(json, 'generalLayoutType')
      ? undefined
      : json['generalLayoutType'],
    generalLayoutImagePath: !exists(json, 'generalLayoutImagePath')
      ? undefined
      : json['generalLayoutImagePath'],
    catchPhrase: !exists(json, 'catchPhrase') ? undefined : json['catchPhrase'],
    aboutCorporationVisibility: !exists(json, 'aboutCorporationVisibility')
      ? undefined
      : json['aboutCorporationVisibility'],
    aboutCorporationText: !exists(json, 'aboutCorporationText')
      ? undefined
      : json['aboutCorporationText'],
    aboutJobPartialLayoutType: !exists(json, 'aboutJobPartialLayoutType')
      ? undefined
      : json['aboutJobPartialLayoutType'],
    aboutJobList: !exists(json, 'aboutJobList')
      ? undefined
      : (json['aboutJobList'] as Array<any>).map(
          PageSettingAboutJobDtoFromJSON
        ),
    staffInterviewVisibility: !exists(json, 'staffInterviewVisibility')
      ? undefined
      : json['staffInterviewVisibility'],
    staffInterviewPartialLayoutType: !exists(
      json,
      'staffInterviewPartialLayoutType'
    )
      ? undefined
      : json['staffInterviewPartialLayoutType'],
    staffInterviewList: !exists(json, 'staffInterviewList')
      ? undefined
      : (json['staffInterviewList'] as Array<any>).map(
          PageSettingStaffInterviewDtoFromJSON
        ),
    jobMgrNos: !exists(json, 'jobMgrNos') ? undefined : json['jobMgrNos'],
    jobPointVisibility: !exists(json, 'jobPointVisibility')
      ? undefined
      : json['jobPointVisibility'],
    jobPointList: !exists(json, 'jobPointList')
      ? undefined
      : (json['jobPointList'] as Array<any>).map(
          PageSettingJobPointDtoFromJSON
        ),
    processVisibility: !exists(json, 'processVisibility')
      ? undefined
      : json['processVisibility'],
    processList: !exists(json, 'processList')
      ? undefined
      : (json['processList'] as Array<any>).map(PageSettingProcessDtoFromJSON),
    messageVisibility: !exists(json, 'messageVisibility')
      ? undefined
      : json['messageVisibility'],
    messageProfile: !exists(json, 'messageProfile')
      ? undefined
      : json['messageProfile'],
    messageBody: !exists(json, 'messageBody') ? undefined : json['messageBody'],
    messageImagePath: !exists(json, 'messageImagePath')
      ? undefined
      : json['messageImagePath'],
    faqVisibility: !exists(json, 'faqVisibility')
      ? undefined
      : json['faqVisibility'],
    faqList: !exists(json, 'faqList')
      ? undefined
      : (json['faqList'] as Array<any>).map(PageSettingFaqDtoFromJSON),
    dispCorpName: !exists(json, 'dispCorpName')
      ? undefined
      : json['dispCorpName'],
    logoImgPath: !exists(json, 'logoImgPath') ? undefined : json['logoImgPath'],
    homepageUrl: !exists(json, 'homepageUrl') ? undefined : json['homepageUrl'],
    twitterUrl: !exists(json, 'twitterUrl') ? undefined : json['twitterUrl'],
    facebookUrl: !exists(json, 'facebookUrl') ? undefined : json['facebookUrl'],
    lineUrl: !exists(json, 'lineUrl') ? undefined : json['lineUrl'],
    instagramUrl: !exists(json, 'instagramUrl')
      ? undefined
      : json['instagramUrl'],
    youtubeUrl: !exists(json, 'youtubeUrl') ? undefined : json['youtubeUrl'],
    corpAddress: !exists(json, 'corpAddress') ? undefined : json['corpAddress'],
    corpEstablishedYear: !exists(json, 'corpEstablishedYear')
      ? undefined
      : json['corpEstablishedYear'],
    corpCapital: !exists(json, 'corpCapital') ? undefined : json['corpCapital'],
    corpEarnings: !exists(json, 'corpEarnings')
      ? undefined
      : json['corpEarnings'],
    corpStockCd: !exists(json, 'corpStockCd') ? undefined : json['corpStockCd'],
    corpPresidentName: !exists(json, 'corpPresidentName')
      ? undefined
      : json['corpPresidentName'],
    corpEmployees: !exists(json, 'corpEmployees')
      ? undefined
      : json['corpEmployees'],
    corpLicenseNo: !exists(json, 'corpLicenseNo')
      ? undefined
      : json['corpLicenseNo'],
    corpLicenseNo2: !exists(json, 'corpLicenseNo2')
      ? undefined
      : json['corpLicenseNo2'],
    corpWelfare: !exists(json, 'corpWelfare') ? undefined : json['corpWelfare'],
    corpBusiness: !exists(json, 'corpBusiness')
      ? undefined
      : json['corpBusiness'],
    topPageVisibility: !exists(json, 'topPageVisibility')
      ? undefined
      : json['topPageVisibility'],
    siteTitle: !exists(json, 'siteTitle') ? undefined : json['siteTitle'],
    siteBrandList: !exists(json, 'siteBrandList')
      ? undefined
      : json['siteBrandList'],
    siteTargetKoyoKeitai: !exists(json, 'siteTargetKoyoKeitai')
      ? undefined
      : json['siteTargetKoyoKeitai'],
    siteDescription: !exists(json, 'siteDescription')
      ? undefined
      : json['siteDescription'],
    updatedAt: !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
    isOpen: !exists(json, 'isOpen') ? undefined : json['isOpen']
  };
}

export function PageSettingAdminReferenceResDtoPreviewToJSON(
  value?: PageSettingAdminReferenceResDtoPreview | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    designType: value.designType,
    color: value.color,
    generalLayoutType: value.generalLayoutType,
    generalLayoutImagePath: value.generalLayoutImagePath,
    catchPhrase: value.catchPhrase,
    aboutCorporationVisibility: value.aboutCorporationVisibility,
    aboutCorporationText: value.aboutCorporationText,
    aboutJobPartialLayoutType: value.aboutJobPartialLayoutType,
    aboutJobList:
      value.aboutJobList === undefined
        ? undefined
        : (value.aboutJobList as Array<any>).map(PageSettingAboutJobDtoToJSON),
    staffInterviewVisibility: value.staffInterviewVisibility,
    staffInterviewPartialLayoutType: value.staffInterviewPartialLayoutType,
    staffInterviewList:
      value.staffInterviewList === undefined
        ? undefined
        : (value.staffInterviewList as Array<any>).map(
            PageSettingStaffInterviewDtoToJSON
          ),
    jobMgrNos: value.jobMgrNos,
    jobPointVisibility: value.jobPointVisibility,
    jobPointList:
      value.jobPointList === undefined
        ? undefined
        : (value.jobPointList as Array<any>).map(PageSettingJobPointDtoToJSON),
    processVisibility: value.processVisibility,
    processList:
      value.processList === undefined
        ? undefined
        : (value.processList as Array<any>).map(PageSettingProcessDtoToJSON),
    messageVisibility: value.messageVisibility,
    messageProfile: value.messageProfile,
    messageBody: value.messageBody,
    messageImagePath: value.messageImagePath,
    faqVisibility: value.faqVisibility,
    faqList:
      value.faqList === undefined
        ? undefined
        : (value.faqList as Array<any>).map(PageSettingFaqDtoToJSON),
    dispCorpName: value.dispCorpName,
    logoImgPath: value.logoImgPath,
    homepageUrl: value.homepageUrl,
    twitterUrl: value.twitterUrl,
    facebookUrl: value.facebookUrl,
    lineUrl: value.lineUrl,
    instagramUrl: value.instagramUrl,
    youtubeUrl: value.youtubeUrl,
    corpAddress: value.corpAddress,
    corpEstablishedYear: value.corpEstablishedYear,
    corpCapital: value.corpCapital,
    corpEarnings: value.corpEarnings,
    corpStockCd: value.corpStockCd,
    corpPresidentName: value.corpPresidentName,
    corpEmployees: value.corpEmployees,
    corpLicenseNo: value.corpLicenseNo,
    corpLicenseNo2: value.corpLicenseNo2,
    corpWelfare: value.corpWelfare,
    corpBusiness: value.corpBusiness,
    topPageVisibility: value.topPageVisibility,
    siteTitle: value.siteTitle,
    siteBrandList: value.siteBrandList,
    siteTargetKoyoKeitai: value.siteTargetKoyoKeitai,
    siteDescription: value.siteDescription,
    updatedAt: value.updatedAt,
    isOpen: value.isOpen
  };
}
