import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import EditSaiyouPageContext from 'context/saiyouPageEditing/EditSaiyouPageContext';
import useAccordionContext from 'hooks/saiyouPageEditing/useAccordionContext';
import { FormData } from 'types/saiyouPageEditing/FormData';
import {
  useFacebookUrlValidationRules,
  useInstagramUrlValidationRules,
  useLINEUrlValidationRules,
  useTwitterUrlValidationRules,
  useYouTubeUrlValidationRules
} from 'validation/rules/saiyouPageEditing';
import ContentAcc from '../../../../common/ContentAcc/ContentAcc';
import TextField from '../../../../common/TextField';
import TabContentFormWrap from '../shared/TabContentFormWrap';
import TabContentInner from '../shared/TabContentInner';
import TabContentText from '../shared/TabContentText';

/**
 * 「SNS設定」アコーディオンを返す
 * @returns 「SNS設定」アコーディオン
 */
const SNSAccordion: FC = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext<FormData>();

  const { onChangeValue } = useContext(EditSaiyouPageContext);

  const { accordionRef, isExpanded, onChange, onEntered, onExited } =
    useAccordionContext('sns');

  const twitterRules = useTwitterUrlValidationRules();
  const facebookRules = useFacebookUrlValidationRules();
  const instagramRules = useInstagramUrlValidationRules();
  const lineRules = useLINEUrlValidationRules();
  const youtubeRules = useYouTubeUrlValidationRules();

  return (
    <ContentAcc
      ref={accordionRef}
      title="SNS設定"
      dispCrown
      isExpanded={isExpanded}
      isError={errors.sns && Object.keys(errors.sns).length > 0}
      onChange={(value) => onChange?.('sns', value)}
      onEntered={onEntered}
      onExited={onExited}
    >
      <TabContentInner>
        <TabContentText text="貴社SNSのプロフィールURLを設定しておくとページ下部にSNSへのリンクが追加され、応募者が働く姿をよりイメージしやすくなります。" />
        <TabContentFormWrap>
          <Controller
            name="sns.twitterUrl"
            control={control}
            rules={{ ...twitterRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="X(旧Twitter)"
                value={value}
                placeholder="https://x.com/アカウント名/"
                helperText={errors.sns?.twitterUrl?.message}
                isError={!!errors.sns?.twitterUrl}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="sns.facebookUrl"
            control={control}
            rules={{ ...facebookRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="Facebook"
                value={value}
                placeholder="https://www.facebook.com/アカウント名/"
                helperText={errors.sns?.facebookUrl?.message}
                isError={!!errors.sns?.facebookUrl}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="sns.instagramUrl"
            control={control}
            rules={{ ...instagramRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="Instagram"
                value={value}
                placeholder="https://www.instagram.com/アカウント名/"
                helperText={errors.sns?.instagramUrl?.message}
                isError={!!errors.sns?.instagramUrl}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="sns.lineUrl"
            control={control}
            rules={{ ...lineRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="LINE"
                value={value}
                placeholder="https://line.me/ または page.line.me/"
                helperText={errors.sns?.lineUrl?.message}
                isError={!!errors.sns?.lineUrl}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
          <Controller
            name="sns.youtubeUrl"
            control={control}
            rules={{ ...youtubeRules, onChange: onChangeValue }}
            render={({
              field: { value, onBlur, onChange },
              formState: { errors }
            }) => (
              <TextField
                label="YouTube"
                value={value}
                placeholder="https://www.youtube.com/xxxxxxxx/"
                helperText={errors.sns?.youtubeUrl?.message}
                isError={!!errors.sns?.youtubeUrl}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </TabContentFormWrap>
      </TabContentInner>
    </ContentAcc>
  );
};

export default SNSAccordion;
