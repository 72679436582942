/* tslint:disable */
/* eslint-disable */
/**
 * 採用ページコボット：管理画面API
 *      [共通認可ロジック]       ・リクエストヘッダー のIdToken に含まれるアカウントNoに紐づくユーザー情報とパスパラメータのCompに紐づく企業情報をバイトル管理API ログインユーザー情報取得APIから取得     　　・ユーザーがアクセス可能なCompかどうかの判断はバイトル管理APIで行う     　　・ユーザーがアクセス不可能であれば403エラーを返す     ・取得したログインユーザーのロールコードとパスパラメータCompMgrNoに応じて制御     　バイトル管理APIでの認可を通った　かつロールコード1〜5・10であればアクセス可能とする       ※ 上記条件を満たさなければ403エラー       ※ さらに細かい権限制御が必要な場合は各エンドポイント単位で制御を行う
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PageSettingAboutJobDto } from './PageSettingAboutJobDto';
import {
  PageSettingAboutJobDtoFromJSON,
  PageSettingAboutJobDtoFromJSONTyped,
  PageSettingAboutJobDtoToJSON
} from './PageSettingAboutJobDto';
import type { PageSettingFaqDto } from './PageSettingFaqDto';
import {
  PageSettingFaqDtoFromJSON,
  PageSettingFaqDtoFromJSONTyped,
  PageSettingFaqDtoToJSON
} from './PageSettingFaqDto';
import type { PageSettingJobPointDto } from './PageSettingJobPointDto';
import {
  PageSettingJobPointDtoFromJSON,
  PageSettingJobPointDtoFromJSONTyped,
  PageSettingJobPointDtoToJSON
} from './PageSettingJobPointDto';
import type { PageSettingProcessDto } from './PageSettingProcessDto';
import {
  PageSettingProcessDtoFromJSON,
  PageSettingProcessDtoFromJSONTyped,
  PageSettingProcessDtoToJSON
} from './PageSettingProcessDto';
import type { PageSettingRegisterEnvironmentInfoDto } from './PageSettingRegisterEnvironmentInfoDto';
import {
  PageSettingRegisterEnvironmentInfoDtoFromJSON,
  PageSettingRegisterEnvironmentInfoDtoFromJSONTyped,
  PageSettingRegisterEnvironmentInfoDtoToJSON
} from './PageSettingRegisterEnvironmentInfoDto';
import type { PageSettingStaffInterviewDto } from './PageSettingStaffInterviewDto';
import {
  PageSettingStaffInterviewDtoFromJSON,
  PageSettingStaffInterviewDtoFromJSONTyped,
  PageSettingStaffInterviewDtoToJSON
} from './PageSettingStaffInterviewDto';

/**
 *
 * @export
 * @interface PageSettingRegisterReqDto
 */
export interface PageSettingRegisterReqDto {
  /**
   * デザイン
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  designType?: PageSettingRegisterReqDtoDesignTypeEnum;
  /**
   * カラー
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  color?: PageSettingRegisterReqDtoColorEnum;
  /**
   * 全体レイアウト
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  generalLayoutType?: PageSettingRegisterReqDtoGeneralLayoutTypeEnum;
  /**
   * レイアウト画像がアップロードされているパス。レイアウトがミニマル以外の場合は必須。
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  generalLayoutImagePath?: string;
  /**
   * キャッチフレーズ。改行文字は文字数制限に含めない
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  catchPhrase?: string;
  /**
   * 会社紹介欄を表示するか
   * @type {number}
   * @memberof PageSettingRegisterReqDto
   */
  aboutCorporationVisibility?: PageSettingRegisterReqDtoAboutCorporationVisibilityEnum;
  /**
   * 会社紹介テキスト
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  aboutCorporationText?: string;
  /**
   * お仕事紹介欄レイアウト
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  aboutJobPartialLayoutType?: PageSettingRegisterReqDtoAboutJobPartialLayoutTypeEnum;
  /**
   * お仕事紹介欄の各職種の情報
   * @type {Array<PageSettingAboutJobDto>}
   * @memberof PageSettingRegisterReqDto
   */
  aboutJobList?: Array<PageSettingAboutJobDto>;
  /**
   * スタッフインタビューを表示するか
   * @type {number}
   * @memberof PageSettingRegisterReqDto
   */
  staffInterviewVisibility?: PageSettingRegisterReqDtoStaffInterviewVisibilityEnum;
  /**
   * スタッフインタビュー欄レイアウト
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  staffInterviewPartialLayoutType?: PageSettingRegisterReqDtoStaffInterviewPartialLayoutTypeEnum;
  /**
   * スタッフインタビュー欄各項目
   * @type {Array<PageSettingStaffInterviewDto>}
   * @memberof PageSettingRegisterReqDto
   */
  staffInterviewList?: Array<PageSettingStaffInterviewDto>;
  /**
   * 募集中のお仕事一覧固定表示
   * @type {Array<string>}
   * @memberof PageSettingRegisterReqDto
   */
  jobMgrNos?: Array<string>;
  /**
   * お仕事のポイント表示
   * @type {number}
   * @memberof PageSettingRegisterReqDto
   */
  jobPointVisibility?: PageSettingRegisterReqDtoJobPointVisibilityEnum;
  /**
   * お仕事のポイント欄各項目
   * @type {Array<PageSettingJobPointDto>}
   * @memberof PageSettingRegisterReqDto
   */
  jobPointList?: Array<PageSettingJobPointDto>;
  /**
   * 応募のプロセス表示
   * @type {number}
   * @memberof PageSettingRegisterReqDto
   */
  processVisibility?: PageSettingRegisterReqDtoProcessVisibilityEnum;
  /**
   * 応募のプロセス各項目
   * @type {Array<PageSettingProcessDto>}
   * @memberof PageSettingRegisterReqDto
   */
  processList?: Array<PageSettingProcessDto>;
  /**
   * 採用担当からのメッセージ表示
   * @type {number}
   * @memberof PageSettingRegisterReqDto
   */
  messageVisibility?: PageSettingRegisterReqDtoMessageVisibilityEnum;
  /**
   * 採用担当からのプロフィール情報
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  messageProfile?: string;
  /**
   * 採用担当からのメッセージ本文
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  messageBody?: string;
  /**
   * 画像がアップロードされているパス
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  messageImagePath?: string;
  /**
   * よくある質問表示
   * @type {number}
   * @memberof PageSettingRegisterReqDto
   */
  faqVisibility?: PageSettingRegisterReqDtoFaqVisibilityEnum;
  /**
   * よくある質問各項目
   * @type {Array<PageSettingFaqDto>}
   * @memberof PageSettingRegisterReqDto
   */
  faqList?: Array<PageSettingFaqDto>;
  /**
   * 表示用企業名
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  dispCorpName?: string;
  /**
   * ロゴがアップロードされているパス
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  logoImgPath?: string;
  /**
   * ホームページURL
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  homepageUrl?: string;
  /**
   * ツイッターURL
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  twitterUrl?: string;
  /**
   * FacebookURL
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  facebookUrl?: string;
  /**
   * LINE URL
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  lineUrl?: string;
  /**
   * InstagramURL
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  instagramUrl?: string;
  /**
   * YouTube URL
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  youtubeUrl?: string;
  /**
   * 企業所在地
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  corpAddress?: string;
  /**
   * 設立年
   * @type {number}
   * @memberof PageSettingRegisterReqDto
   */
  corpEstablishedYear?: number;
  /**
   * 資本金
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  corpCapital?: string;
  /**
   * 売上高
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  corpEarnings?: string;
  /**
   * 株式公開区分
   * @type {number}
   * @memberof PageSettingRegisterReqDto
   */
  corpStockCd?: PageSettingRegisterReqDtoCorpStockCdEnum;
  /**
   * 代表者名
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  corpPresidentName?: string;
  /**
   * 従業員数
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  corpEmployees?: string;
  /**
   * 派遣許可番号
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  corpLicenseNo?: string;
  /**
   * 有料職業紹介事業許可番号
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  corpLicenseNo2?: string;
  /**
   * 福利厚生・待遇
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  corpWelfare?: string;
  /**
   * 事業内容 改行文字は文字数制限に含めない(改行最大160個まで)
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  corpBusiness?: string;
  /**
   * 採用TOPページ表示設定
   * @type {number}
   * @memberof PageSettingRegisterReqDto
   */
  topPageVisibility?: PageSettingRegisterReqDtoTopPageVisibilityEnum;
  /**
   * サイトタイトル
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  siteTitle?: string;
  /**
   * サイトブランド
   * @type {Array<string>}
   * @memberof PageSettingRegisterReqDto
   */
  siteBrandList?: Array<string>;
  /**
   * ターゲット（雇用形態）
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  siteTargetKoyoKeitai?: PageSettingRegisterReqDtoSiteTargetKoyoKeitaiEnum;
  /**
   * サイト説明文
   * @type {string}
   * @memberof PageSettingRegisterReqDto
   */
  siteDescription?: string;
  /**
   * 処理対象
   * @type {Array<PageSettingRegisterEnvironmentInfoDto>}
   * @memberof PageSettingRegisterReqDto
   */
  targets?: Array<PageSettingRegisterEnvironmentInfoDto>;
}

/**
 * @export
 */
export const PageSettingRegisterReqDtoDesignTypeEnum = {
  Basic: 'basic',
  Stylish: 'stylish',
  Pop: 'pop',
  Natural: 'natural'
} as const;
export type PageSettingRegisterReqDtoDesignTypeEnum =
  (typeof PageSettingRegisterReqDtoDesignTypeEnum)[keyof typeof PageSettingRegisterReqDtoDesignTypeEnum];

/**
 * @export
 */
export const PageSettingRegisterReqDtoColorEnum = {
  Red: 'red',
  Orange: 'orange',
  Green: 'green',
  Blue: 'blue',
  Gray: 'gray',
  Purple: 'purple'
} as const;
export type PageSettingRegisterReqDtoColorEnum =
  (typeof PageSettingRegisterReqDtoColorEnum)[keyof typeof PageSettingRegisterReqDtoColorEnum];

/**
 * @export
 */
export const PageSettingRegisterReqDtoGeneralLayoutTypeEnum = {
  Minimal: 'minimal',
  Standard: 'standard',
  Rich: 'rich'
} as const;
export type PageSettingRegisterReqDtoGeneralLayoutTypeEnum =
  (typeof PageSettingRegisterReqDtoGeneralLayoutTypeEnum)[keyof typeof PageSettingRegisterReqDtoGeneralLayoutTypeEnum];

/**
 * @export
 */
export const PageSettingRegisterReqDtoAboutCorporationVisibilityEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1
} as const;
export type PageSettingRegisterReqDtoAboutCorporationVisibilityEnum =
  (typeof PageSettingRegisterReqDtoAboutCorporationVisibilityEnum)[keyof typeof PageSettingRegisterReqDtoAboutCorporationVisibilityEnum];

/**
 * @export
 */
export const PageSettingRegisterReqDtoAboutJobPartialLayoutTypeEnum = {
  Default: 'default',
  Card: 'card'
} as const;
export type PageSettingRegisterReqDtoAboutJobPartialLayoutTypeEnum =
  (typeof PageSettingRegisterReqDtoAboutJobPartialLayoutTypeEnum)[keyof typeof PageSettingRegisterReqDtoAboutJobPartialLayoutTypeEnum];

/**
 * @export
 */
export const PageSettingRegisterReqDtoStaffInterviewVisibilityEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1
} as const;
export type PageSettingRegisterReqDtoStaffInterviewVisibilityEnum =
  (typeof PageSettingRegisterReqDtoStaffInterviewVisibilityEnum)[keyof typeof PageSettingRegisterReqDtoStaffInterviewVisibilityEnum];

/**
 * @export
 */
export const PageSettingRegisterReqDtoStaffInterviewPartialLayoutTypeEnum = {
  Default: 'default',
  Card: 'card'
} as const;
export type PageSettingRegisterReqDtoStaffInterviewPartialLayoutTypeEnum =
  (typeof PageSettingRegisterReqDtoStaffInterviewPartialLayoutTypeEnum)[keyof typeof PageSettingRegisterReqDtoStaffInterviewPartialLayoutTypeEnum];

/**
 * @export
 */
export const PageSettingRegisterReqDtoJobPointVisibilityEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1
} as const;
export type PageSettingRegisterReqDtoJobPointVisibilityEnum =
  (typeof PageSettingRegisterReqDtoJobPointVisibilityEnum)[keyof typeof PageSettingRegisterReqDtoJobPointVisibilityEnum];

/**
 * @export
 */
export const PageSettingRegisterReqDtoProcessVisibilityEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1
} as const;
export type PageSettingRegisterReqDtoProcessVisibilityEnum =
  (typeof PageSettingRegisterReqDtoProcessVisibilityEnum)[keyof typeof PageSettingRegisterReqDtoProcessVisibilityEnum];

/**
 * @export
 */
export const PageSettingRegisterReqDtoMessageVisibilityEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1
} as const;
export type PageSettingRegisterReqDtoMessageVisibilityEnum =
  (typeof PageSettingRegisterReqDtoMessageVisibilityEnum)[keyof typeof PageSettingRegisterReqDtoMessageVisibilityEnum];

/**
 * @export
 */
export const PageSettingRegisterReqDtoFaqVisibilityEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1
} as const;
export type PageSettingRegisterReqDtoFaqVisibilityEnum =
  (typeof PageSettingRegisterReqDtoFaqVisibilityEnum)[keyof typeof PageSettingRegisterReqDtoFaqVisibilityEnum];

/**
 * @export
 */
export const PageSettingRegisterReqDtoCorpStockCdEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1
} as const;
export type PageSettingRegisterReqDtoCorpStockCdEnum =
  (typeof PageSettingRegisterReqDtoCorpStockCdEnum)[keyof typeof PageSettingRegisterReqDtoCorpStockCdEnum];

/**
 * @export
 */
export const PageSettingRegisterReqDtoTopPageVisibilityEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1
} as const;
export type PageSettingRegisterReqDtoTopPageVisibilityEnum =
  (typeof PageSettingRegisterReqDtoTopPageVisibilityEnum)[keyof typeof PageSettingRegisterReqDtoTopPageVisibilityEnum];

/**
 * @export
 */
export const PageSettingRegisterReqDtoSiteTargetKoyoKeitaiEnum = {
  Normal: 'normal',
  Regular: 'regular',
  Contract: 'contract',
  Haken: 'haken',
  Outsg: 'outsg',
  NlimEhaken: 'nlim_ehaken',
  SyokaiRhaken: 'syokai_rhaken'
} as const;
export type PageSettingRegisterReqDtoSiteTargetKoyoKeitaiEnum =
  (typeof PageSettingRegisterReqDtoSiteTargetKoyoKeitaiEnum)[keyof typeof PageSettingRegisterReqDtoSiteTargetKoyoKeitaiEnum];

/**
 * Check if a given object implements the PageSettingRegisterReqDto interface.
 */
export function instanceOfPageSettingRegisterReqDto(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function PageSettingRegisterReqDtoFromJSON(
  json: any
): PageSettingRegisterReqDto {
  return PageSettingRegisterReqDtoFromJSONTyped(json, false);
}

export function PageSettingRegisterReqDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PageSettingRegisterReqDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    designType: !exists(json, 'designType') ? undefined : json['designType'],
    color: !exists(json, 'color') ? undefined : json['color'],
    generalLayoutType: !exists(json, 'generalLayoutType')
      ? undefined
      : json['generalLayoutType'],
    generalLayoutImagePath: !exists(json, 'generalLayoutImagePath')
      ? undefined
      : json['generalLayoutImagePath'],
    catchPhrase: !exists(json, 'catchPhrase') ? undefined : json['catchPhrase'],
    aboutCorporationVisibility: !exists(json, 'aboutCorporationVisibility')
      ? undefined
      : json['aboutCorporationVisibility'],
    aboutCorporationText: !exists(json, 'aboutCorporationText')
      ? undefined
      : json['aboutCorporationText'],
    aboutJobPartialLayoutType: !exists(json, 'aboutJobPartialLayoutType')
      ? undefined
      : json['aboutJobPartialLayoutType'],
    aboutJobList: !exists(json, 'aboutJobList')
      ? undefined
      : (json['aboutJobList'] as Array<any>).map(
          PageSettingAboutJobDtoFromJSON
        ),
    staffInterviewVisibility: !exists(json, 'staffInterviewVisibility')
      ? undefined
      : json['staffInterviewVisibility'],
    staffInterviewPartialLayoutType: !exists(
      json,
      'staffInterviewPartialLayoutType'
    )
      ? undefined
      : json['staffInterviewPartialLayoutType'],
    staffInterviewList: !exists(json, 'staffInterviewList')
      ? undefined
      : (json['staffInterviewList'] as Array<any>).map(
          PageSettingStaffInterviewDtoFromJSON
        ),
    jobMgrNos: !exists(json, 'jobMgrNos') ? undefined : json['jobMgrNos'],
    jobPointVisibility: !exists(json, 'jobPointVisibility')
      ? undefined
      : json['jobPointVisibility'],
    jobPointList: !exists(json, 'jobPointList')
      ? undefined
      : (json['jobPointList'] as Array<any>).map(
          PageSettingJobPointDtoFromJSON
        ),
    processVisibility: !exists(json, 'processVisibility')
      ? undefined
      : json['processVisibility'],
    processList: !exists(json, 'processList')
      ? undefined
      : (json['processList'] as Array<any>).map(PageSettingProcessDtoFromJSON),
    messageVisibility: !exists(json, 'messageVisibility')
      ? undefined
      : json['messageVisibility'],
    messageProfile: !exists(json, 'messageProfile')
      ? undefined
      : json['messageProfile'],
    messageBody: !exists(json, 'messageBody') ? undefined : json['messageBody'],
    messageImagePath: !exists(json, 'messageImagePath')
      ? undefined
      : json['messageImagePath'],
    faqVisibility: !exists(json, 'faqVisibility')
      ? undefined
      : json['faqVisibility'],
    faqList: !exists(json, 'faqList')
      ? undefined
      : (json['faqList'] as Array<any>).map(PageSettingFaqDtoFromJSON),
    dispCorpName: !exists(json, 'dispCorpName')
      ? undefined
      : json['dispCorpName'],
    logoImgPath: !exists(json, 'logoImgPath') ? undefined : json['logoImgPath'],
    homepageUrl: !exists(json, 'homepageUrl') ? undefined : json['homepageUrl'],
    twitterUrl: !exists(json, 'twitterUrl') ? undefined : json['twitterUrl'],
    facebookUrl: !exists(json, 'facebookUrl') ? undefined : json['facebookUrl'],
    lineUrl: !exists(json, 'lineUrl') ? undefined : json['lineUrl'],
    instagramUrl: !exists(json, 'instagramUrl')
      ? undefined
      : json['instagramUrl'],
    youtubeUrl: !exists(json, 'youtubeUrl') ? undefined : json['youtubeUrl'],
    corpAddress: !exists(json, 'corpAddress') ? undefined : json['corpAddress'],
    corpEstablishedYear: !exists(json, 'corpEstablishedYear')
      ? undefined
      : json['corpEstablishedYear'],
    corpCapital: !exists(json, 'corpCapital') ? undefined : json['corpCapital'],
    corpEarnings: !exists(json, 'corpEarnings')
      ? undefined
      : json['corpEarnings'],
    corpStockCd: !exists(json, 'corpStockCd') ? undefined : json['corpStockCd'],
    corpPresidentName: !exists(json, 'corpPresidentName')
      ? undefined
      : json['corpPresidentName'],
    corpEmployees: !exists(json, 'corpEmployees')
      ? undefined
      : json['corpEmployees'],
    corpLicenseNo: !exists(json, 'corpLicenseNo')
      ? undefined
      : json['corpLicenseNo'],
    corpLicenseNo2: !exists(json, 'corpLicenseNo2')
      ? undefined
      : json['corpLicenseNo2'],
    corpWelfare: !exists(json, 'corpWelfare') ? undefined : json['corpWelfare'],
    corpBusiness: !exists(json, 'corpBusiness')
      ? undefined
      : json['corpBusiness'],
    topPageVisibility: !exists(json, 'topPageVisibility')
      ? undefined
      : json['topPageVisibility'],
    siteTitle: !exists(json, 'siteTitle') ? undefined : json['siteTitle'],
    siteBrandList: !exists(json, 'siteBrandList')
      ? undefined
      : json['siteBrandList'],
    siteTargetKoyoKeitai: !exists(json, 'siteTargetKoyoKeitai')
      ? undefined
      : json['siteTargetKoyoKeitai'],
    siteDescription: !exists(json, 'siteDescription')
      ? undefined
      : json['siteDescription'],
    targets: !exists(json, 'targets')
      ? undefined
      : (json['targets'] as Array<any>).map(
          PageSettingRegisterEnvironmentInfoDtoFromJSON
        )
  };
}

export function PageSettingRegisterReqDtoToJSON(
  value?: PageSettingRegisterReqDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    designType: value.designType,
    color: value.color,
    generalLayoutType: value.generalLayoutType,
    generalLayoutImagePath: value.generalLayoutImagePath,
    catchPhrase: value.catchPhrase,
    aboutCorporationVisibility: value.aboutCorporationVisibility,
    aboutCorporationText: value.aboutCorporationText,
    aboutJobPartialLayoutType: value.aboutJobPartialLayoutType,
    aboutJobList:
      value.aboutJobList === undefined
        ? undefined
        : (value.aboutJobList as Array<any>).map(PageSettingAboutJobDtoToJSON),
    staffInterviewVisibility: value.staffInterviewVisibility,
    staffInterviewPartialLayoutType: value.staffInterviewPartialLayoutType,
    staffInterviewList:
      value.staffInterviewList === undefined
        ? undefined
        : (value.staffInterviewList as Array<any>).map(
            PageSettingStaffInterviewDtoToJSON
          ),
    jobMgrNos: value.jobMgrNos,
    jobPointVisibility: value.jobPointVisibility,
    jobPointList:
      value.jobPointList === undefined
        ? undefined
        : (value.jobPointList as Array<any>).map(PageSettingJobPointDtoToJSON),
    processVisibility: value.processVisibility,
    processList:
      value.processList === undefined
        ? undefined
        : (value.processList as Array<any>).map(PageSettingProcessDtoToJSON),
    messageVisibility: value.messageVisibility,
    messageProfile: value.messageProfile,
    messageBody: value.messageBody,
    messageImagePath: value.messageImagePath,
    faqVisibility: value.faqVisibility,
    faqList:
      value.faqList === undefined
        ? undefined
        : (value.faqList as Array<any>).map(PageSettingFaqDtoToJSON),
    dispCorpName: value.dispCorpName,
    logoImgPath: value.logoImgPath,
    homepageUrl: value.homepageUrl,
    twitterUrl: value.twitterUrl,
    facebookUrl: value.facebookUrl,
    lineUrl: value.lineUrl,
    instagramUrl: value.instagramUrl,
    youtubeUrl: value.youtubeUrl,
    corpAddress: value.corpAddress,
    corpEstablishedYear: value.corpEstablishedYear,
    corpCapital: value.corpCapital,
    corpEarnings: value.corpEarnings,
    corpStockCd: value.corpStockCd,
    corpPresidentName: value.corpPresidentName,
    corpEmployees: value.corpEmployees,
    corpLicenseNo: value.corpLicenseNo,
    corpLicenseNo2: value.corpLicenseNo2,
    corpWelfare: value.corpWelfare,
    corpBusiness: value.corpBusiness,
    topPageVisibility: value.topPageVisibility,
    siteTitle: value.siteTitle,
    siteBrandList: value.siteBrandList,
    siteTargetKoyoKeitai: value.siteTargetKoyoKeitai,
    siteDescription: value.siteDescription,
    targets:
      value.targets === undefined
        ? undefined
        : (value.targets as Array<any>).map(
            PageSettingRegisterEnvironmentInfoDtoToJSON
          )
  };
}
