import { RefObject, useCallback, useRef } from 'react';
import {
  PageSettingAboutJobDto,
  PageSettingFaqDto,
  PageSettingJobPointDto,
  PageSettingProcessDto,
  PageSettingReferenceResDto,
  PageSettingStaffInterviewDto
} from 'api-client/public';
import { StockStatus } from 'enums/saiyouPageEditing/StockStatus';
import { PreviewFormData } from 'types/saiyouPageEditing/PreviewFormData';

type NestedPartial<T> = {
  [K in keyof T]?: T[K] extends Array<infer R>
    ? Array<NestedPartial<R>>
    : NestedPartial<T[K]>;
};

const useEditPreview = (): {
  previewIFrame: RefObject<HTMLIFrameElement>;
  updatePreview: (data: PreviewFormData) => void;
} => {
  const previewIFrame = useRef<HTMLIFrameElement>(null);

  const formatData = useCallback(
    (src: PreviewFormData): NestedPartial<PageSettingReferenceResDto> => {
      const dst: NestedPartial<PageSettingReferenceResDto> = {};

      if (src.style?.pageDesign) dst.designType = src.style.pageDesign;
      if (src.style?.pageColor) dst.color = src.style.pageColor;

      if (src.topImage?.layout) dst.generalLayoutType = src.topImage.layout;
      if (src.topImage?.layoutImagePath)
        dst.generalLayoutImagePath = src.topImage.layoutImagePath;
      if (src.topImage?.catchPhrase) dst.catchPhrase = src.topImage.catchPhrase;

      if (src.corporationIntroduction?.isVisible != null)
        dst.aboutCorporationVisibility = src.corporationIntroduction.isVisible;
      if (src.corporationIntroduction?.text)
        dst.aboutCorporationText = src.corporationIntroduction.text;

      if (src.jobIntroduction?.layout)
        dst.aboutJobPartialLayoutType = src.jobIntroduction.layout;
      if (
        src.jobIntroduction?.occupations &&
        src.jobIntroduction.occupations.length > 0
      ) {
        dst.aboutJobList = src.jobIntroduction.occupations.map((occupation) => {
          const dstAboutJob: Partial<PageSettingAboutJobDto> = {};
          if (occupation?.code) dstAboutJob.shok3Cd = occupation.code;
          if (occupation?.name) dstAboutJob.dispName = occupation.name;
          if (occupation?.description)
            dstAboutJob.text = occupation.description;
          if (occupation?.imagePath)
            dstAboutJob.imagePath = occupation.imagePath;

          return dstAboutJob;
        });
      }

      if (src.staffInterview?.isVisible != null)
        dst.staffInterviewVisibility = src.staffInterview.isVisible;
      if (src.staffInterview?.layout)
        dst.staffInterviewPartialLayoutType = src.staffInterview.layout;
      if (
        src.staffInterview?.interviews &&
        src.staffInterview.interviews.length > 0
      ) {
        dst.staffInterviewList = src.staffInterview.interviews.map(
          (interview) => {
            const dstInterview: Partial<PageSettingStaffInterviewDto> = {};
            if (interview?.profile) dstInterview.title = interview.profile;
            if (interview?.text) dstInterview.body = interview.text;
            if (interview?.imagePath)
              dstInterview.imagePath = interview.imagePath;
            if (interview?.youtubeUrl)
              dstInterview.youtubeUrl = interview.youtubeUrl;

            return dstInterview;
          }
        );
      }

      if (src.jobMgrNos && src.jobMgrNos.length > 0) {
        dst.jobMgrNos = src.jobMgrNos
          .map((jobMgrNo) => jobMgrNo || '')
          .filter((jobMgrNo) => !!jobMgrNo);
      }

      if (src.jobPoint?.isVisible != null)
        dst.jobPointVisibility = src.jobPoint.isVisible;
      if (src.jobPoint?.points && src.jobPoint.points.length > 0) {
        dst.jobPointList = src.jobPoint.points.map((point) => {
          const dstJobPoint: Partial<PageSettingJobPointDto> = {};
          if (point?.title) dstJobPoint.title = point.title;
          if (point?.content) dstJobPoint.body = point.content;
          if (point?.iconPath) dstJobPoint.imagePath = point.iconPath;

          return dstJobPoint;
        });
      }

      if (src.afterProcess?.isVisible != null)
        dst.processVisibility = src.afterProcess.isVisible;
      if (
        src.afterProcess?.processList &&
        src.afterProcess.processList.length > 0
      ) {
        dst.processList = src.afterProcess.processList.map((process) => {
          const dstProcess: Partial<PageSettingProcessDto> = {};
          if (process?.title) dstProcess.title = process.title;
          if (process?.content) dstProcess.body = process.content;

          return dstProcess;
        });
      }

      if (src.message?.isVisible != null)
        dst.messageVisibility = src.message.isVisible;
      if (src.message?.profile) dst.messageProfile = src.message.profile;
      if (src.message?.text) dst.messageBody = src.message.text;
      if (src.message?.imagePath) dst.messageImagePath = src.message.imagePath;

      if (src.faq?.isVisible != null) dst.faqVisibility = src.faq.isVisible;
      if (src.faq?.questions && src.faq.questions.length > 0) {
        dst.faqList = src.faq.questions.map((question) => {
          const dstQuestion: Partial<PageSettingFaqDto> = {};
          if (question?.title) dstQuestion.title = question.title;
          if (question?.content) dstQuestion.body = question.content;

          return dstQuestion;
        });
      }

      if (src.corporationName) dst.dispCorpName = src.corporationName;

      if (src.logoImagePath) dst.logoImgPath = src.logoImagePath;

      if (src.homepageUrl) dst.homepageUrl = src.homepageUrl;

      if (src.sns?.twitterUrl) dst.twitterUrl = src.sns.twitterUrl;
      if (src.sns?.facebookUrl) dst.facebookUrl = src.sns.facebookUrl;
      if (src.sns?.lineUrl) dst.lineUrl = src.sns.lineUrl;
      if (src.sns?.instagramUrl) dst.instagramUrl = src.sns.instagramUrl;
      if (src.sns?.youtubeUrl) dst.youtubeUrl = src.sns.youtubeUrl;

      if (src.corporationAbout?.address)
        dst.corpAddress = src.corporationAbout.address;
      if (src.corporationAbout?.establishedYear != null)
        dst.corpEstablishedYear = src.corporationAbout.establishedYear;
      if (src.corporationAbout?.capital)
        dst.corpCapital = src.corporationAbout.capital;
      if (src.corporationAbout?.earnings)
        dst.corpEarnings = src.corporationAbout.earnings;
      if (
        src.corporationAbout?.stockStatus != null &&
        src.corporationAbout.stockStatus !== StockStatus.Unselected
      )
        dst.corpStockCd = src.corporationAbout.stockStatus;
      if (src.corporationAbout?.presidentName)
        dst.corpPresidentName = src.corporationAbout.presidentName;
      if (src.corporationAbout?.employeesLength)
        dst.corpEmployees = src.corporationAbout.employeesLength;
      if (src.corporationAbout?.licenseNo)
        dst.corpLicenseNo = src.corporationAbout.licenseNo;
      if (src.corporationAbout?.licenseNo2)
        dst.corpLicenseNo2 = src.corporationAbout.licenseNo2;
      if (src.corporationAbout?.welfare)
        dst.corpWelfare = src.corporationAbout.welfare;
      if (src.corporationAbout?.business)
        dst.corpBusiness = src.corporationAbout.business;

      if (src.isVisibleTopPage != null)
        dst.topPageVisibility = src.isVisibleTopPage;

      if (src.siteTitle) dst.siteTitle = src.siteTitle;

      if (src.brandNames && src.brandNames.length > 0) {
        dst.siteBrandList = src.brandNames
          .map((brandName) => brandName || '')
          .filter((brandName) => !!brandName);
      }

      if (src.employmentStatus) dst.siteTargetKoyoKeitai = src.employmentStatus;

      if (src.siteDescription) dst.siteDescription = src.siteDescription;

      return dst;
    },
    []
  );

  const updatePreview = useCallback(
    (data: PreviewFormData) => {
      previewIFrame.current?.contentWindow?.postMessage(formatData(data), '*');
    },
    [previewIFrame, formatData]
  );

  return { previewIFrame, updatePreview };
};

export default useEditPreview;
