import { useCallback } from 'react';
import {
  PageSettingAboutJobDto,
  PageSettingAdminReferenceResDtoPreview,
  PageSettingAdminReferenceResDtoPublished,
  PageSettingRegisterReqDto,
  PageSettingStaffInterviewDto
} from 'api-client/management';
import { StockStatus } from 'enums/saiyouPageEditing/StockStatus';
import { Visibility } from 'enums/Visibility';
import { FormData } from 'types/saiyouPageEditing/FormData';

/**
 * フォームデータの変換Hooks
 * @returns 変換関数群
 */
const useFormDataConverter = (): {
  toRequestBody: (formData: FormData) => PageSettingRegisterReqDto;
  toFormData: (
    responseBody:
      | PageSettingAdminReferenceResDtoPreview
      | PageSettingAdminReferenceResDtoPublished
  ) => FormData;
} => {
  const toRequestBody = useCallback(
    (formData: FormData): PageSettingRegisterReqDto => {
      const requestBody: PageSettingRegisterReqDto = {
        designType: formData.style.pageDesign,
        color: formData.style.pageColor,
        generalLayoutType: formData.topImage.layout,
        catchPhrase: formData.topImage.catchPhrase,
        aboutCorporationVisibility: formData.corporationIntroduction.isVisible,
        aboutJobPartialLayoutType: formData.jobIntroduction.layout,
        aboutJobList: formData.jobIntroduction.occupations.map((occupation) => {
          const dst: PageSettingAboutJobDto = {
            shok3Cd: occupation.code,
            dispName: occupation.name,
            text: occupation.description
          };

          if (occupation.imagePath) dst.imagePath = occupation.imagePath;

          return dst;
        }),
        staffInterviewVisibility: formData.staffInterview.isVisible,
        staffInterviewPartialLayoutType: formData.staffInterview.layout,
        staffInterviewList: formData.staffInterview.interviews.map(
          (interview) => {
            const dst: PageSettingStaffInterviewDto = {
              title: interview.profile,
              body: interview.text
            };

            if (interview.imagePath) dst.imagePath = interview.imagePath;
            if (interview.youtubeUrl) dst.youtubeUrl = interview.youtubeUrl;

            return dst;
          }
        ),
        jobPointVisibility: formData.jobPoint.isVisible,
        jobPointList: formData.jobPoint.points.map((point) => ({
          title: point.title,
          body: point.content,
          imagePath: point.iconPath
        })),
        processVisibility: formData.afterProcess.isVisible,
        processList: formData.afterProcess.processList.map((process) => ({
          title: process.title,
          body: process.content
        })),
        messageVisibility: formData.message.isVisible,
        faqVisibility: formData.faq.isVisible,
        faqList: formData.faq.questions.map((question) => ({
          title: question.title,
          body: question.content
        })),
        dispCorpName: formData.corporationName,
        corpAddress: formData.corporationAbout.address,
        corpBusiness: formData.corporationAbout.business,
        topPageVisibility: formData.isVisibleTopPage,
        siteTargetKoyoKeitai: formData.employmentStatus,
        siteDescription: formData.siteDescription
      };

      if (formData.homepageUrl) requestBody.homepageUrl = formData.homepageUrl;

      if (formData.topImage.layoutImagePath)
        requestBody.generalLayoutImagePath = formData.topImage.layoutImagePath;

      if (formData.corporationIntroduction.text)
        requestBody.aboutCorporationText =
          formData.corporationIntroduction.text;

      if (formData.message.profile)
        requestBody.messageProfile = formData.message.profile;
      if (formData.message.text)
        requestBody.messageBody = formData.message.text;
      if (formData.message.imagePath)
        requestBody.messageImagePath = formData.message.imagePath;

      if (formData.logoImagePath)
        requestBody.logoImgPath = formData.logoImagePath;

      if (formData.sns.twitterUrl)
        requestBody.twitterUrl = formData.sns.twitterUrl;
      if (formData.sns.facebookUrl)
        requestBody.facebookUrl = formData.sns.facebookUrl;
      if (formData.sns.lineUrl) requestBody.lineUrl = formData.sns.lineUrl;
      if (formData.sns.instagramUrl)
        requestBody.instagramUrl = formData.sns.instagramUrl;
      if (formData.sns.youtubeUrl)
        requestBody.youtubeUrl = formData.sns.youtubeUrl;

      if (formData.corporationAbout.establishedYear != null)
        requestBody.corpEstablishedYear =
          formData.corporationAbout.establishedYear;
      if (formData.corporationAbout.capital)
        requestBody.corpCapital = formData.corporationAbout.capital;
      if (formData.corporationAbout.earnings)
        requestBody.corpEarnings = formData.corporationAbout.earnings;
      if (
        formData.corporationAbout.stockStatus != null &&
        formData.corporationAbout.stockStatus !== StockStatus.Unselected
      )
        requestBody.corpStockCd = formData.corporationAbout.stockStatus;
      if (formData.corporationAbout.presidentName)
        requestBody.corpPresidentName = formData.corporationAbout.presidentName;
      if (formData.corporationAbout.employeesLength)
        requestBody.corpEmployees = formData.corporationAbout.employeesLength;
      if (formData.corporationAbout.licenseNo)
        requestBody.corpLicenseNo = formData.corporationAbout.licenseNo;
      if (formData.corporationAbout.licenseNo2)
        requestBody.corpLicenseNo2 = formData.corporationAbout.licenseNo2;
      if (formData.corporationAbout.welfare)
        requestBody.corpWelfare = formData.corporationAbout.welfare;

      if (formData.siteTitle) requestBody.siteTitle = formData.siteTitle;

      const jobMgrNos = formData.jobMgrNos.filter((jobMgrNo) => !!jobMgrNo);
      if (jobMgrNos.length > 0) requestBody.jobMgrNos = jobMgrNos;

      const brandNames = formData.brandNames.filter((brandName) => !!brandName);
      if (brandNames.length > 0) requestBody.siteBrandList = brandNames;

      return requestBody;
    },
    []
  );

  /**
   * Formにセットする表示/非表示値をサニタイズして返す
   * @param val
   * @param defaultValue
   * @returns
   */
  const visibilitySetter = (
    val: Visibility | undefined,
    defaultValue: Visibility = Visibility.Invisible
  ) => (val == undefined ? defaultValue : val);

  const toFormData = useCallback(
    (
      src:
        | PageSettingAdminReferenceResDtoPreview
        | PageSettingAdminReferenceResDtoPublished
    ): FormData => ({
      style: {
        pageDesign: src.designType || 'basic',
        pageColor: src.color || 'gray'
      },
      topImage: {
        layout: src.generalLayoutType || 'minimal',
        layoutImagePath: src.generalLayoutImagePath,
        catchPhrase: src.catchPhrase || ''
      },
      corporationIntroduction: {
        isVisible: visibilitySetter(src.aboutCorporationVisibility),
        text: src.aboutCorporationText
      },
      jobIntroduction: {
        layout: src.aboutJobPartialLayoutType || 'default',
        occupations:
          src.aboutJobList?.map((job) => ({
            code: job.shok3Cd || '',
            name: job.dispName || '',
            description: job.text || '',
            imagePath: job.imagePath
          })) || []
      },
      staffInterview: {
        isVisible: visibilitySetter(src.staffInterviewVisibility),
        layout: src.staffInterviewPartialLayoutType || 'default',
        interviews:
          src.staffInterviewList?.map((interview) => ({
            profile: interview.title || '',
            text: interview.body || '',
            imagePath: interview.imagePath,
            youtubeUrl: interview.youtubeUrl
          })) || []
      },
      jobMgrNos: src.jobMgrNos?.map((jobMgrNo) => jobMgrNo) || [],
      jobPoint: {
        isVisible: visibilitySetter(src.jobPointVisibility),
        points:
          src.jobPointList?.map((point) => ({
            title: point.title || '',
            content: point.body || '',
            iconPath: point.imagePath || ''
          })) || []
      },
      afterProcess: {
        isVisible: visibilitySetter(src.processVisibility),
        processList:
          src.processList?.map((process) => ({
            title: process.title || '',
            content: process.body || ''
          })) || []
      },
      message: {
        isVisible: visibilitySetter(src.messageVisibility),
        profile: src.messageProfile,
        text: src.messageBody,
        imagePath: src.messageImagePath
      },
      faq: {
        isVisible: visibilitySetter(src.faqVisibility),
        questions:
          src.faqList?.map((faq) => ({
            title: faq.title || '',
            content: faq.body || ''
          })) || []
      },
      corporationName: src.dispCorpName || '',
      logoImagePath: src.logoImgPath,
      homepageUrl: src.homepageUrl || '',
      sns: {
        twitterUrl: src.twitterUrl,
        facebookUrl: src.facebookUrl,
        instagramUrl: src.instagramUrl,
        lineUrl: src.lineUrl,
        youtubeUrl: src.youtubeUrl
      },
      corporationAbout: {
        address: src.corpAddress || '',
        establishedYear: src.corpEstablishedYear,
        capital: src.corpCapital,
        earnings: src.corpEarnings,
        stockStatus:
          src.corpStockCd !== undefined
            ? src.corpStockCd
            : StockStatus.Unselected,
        presidentName: src.corpPresidentName,
        employeesLength: src.corpEmployees,
        licenseNo: src.corpLicenseNo,
        licenseNo2: src.corpLicenseNo2,
        welfare: src.corpWelfare,
        business: src.corpBusiness || ''
      },
      isVisibleTopPage: visibilitySetter(
        src.topPageVisibility,
        Visibility.Visible
      ),
      siteTitle: src.siteTitle,
      brandNames: src.siteBrandList?.map((brand) => brand) || [],
      employmentStatus: src.siteTargetKoyoKeitai || 'normal',
      siteDescription: src.siteDescription || ''
    }),
    []
  );

  return { toRequestBody, toFormData };
};

export default useFormDataConverter;
